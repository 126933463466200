.App {
  text-align: center;
}

.main-container {
  z-index: 2;
}

a {
  color: #ab46bb !important;
  text-decoration: none;
}

a:visited {
  color: #ab46bb !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}