.links {
  display: flex;
  align-items: center;
}

.links img {
  /* height: 80px;
  width: 80px; */
  padding: 10px;
  transition: transform 0.5s;
}

.links img:hover {
  opacity: .7;
  transform: scale(0.95);
}

.links a {
  text-decoration: none;
  font-size: 0;
}

.links img[alt="artist"] {
  padding-top:15px;
}
